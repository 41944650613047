<template>
    <van-config-provider :theme-vars="themeVars" theme-vars-scope="global">
        <router-view></router-view>
    </van-config-provider>
</template>
<script setup>
const themeVars = reactive({
    primaryColor: "#465161"
});
</script>
<style></style>
