export default [
    {
        path: "/404",
        name: "NotFound",
        component: () => import(/* webpackChunkName: "error" */ "@/views/exception/404.vue"),
        meta: {
            tabName: "404",
            keepAlive: false
        }
    },
    {
        path: "/503",
        name: "SystemError",
        component: () => import(/* webpackChunkName: "error" */ "@/views/exception/503.vue"),
        meta: {
            tabName: "503",
            keepAlive: false
        }
    }
];
