import "vant/es/toast/style/index";
import { showLoadingToast, showToast, showSuccessToast, showFailToast, closeToast } from "vant";

export const toast = showToast;
export const toastSuccess = showSuccessToast;
export const toastFail = showFailToast;
export const toastClose = closeToast;
export const showLoading = function (text) {
    return showLoadingToast({
        duration: 0,
        forbidClick: true,
        message: text || "加载中..."
    });
};