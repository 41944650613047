import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';


export default createStore({
    state: {
        selectedAddress: '',
        selectedCustomers: []
    },
    mutations: {
        setSelectedAddress(state, payload) {
            state.selectedAddress = payload;
        },
        setSelectedCustomers(state, payload) {
            state.selectedCustomers = payload;
        }
    },
    plugins: [createPersistedState({
        key: "STORE_DATA",
        storage: window.sessionStorage,
    })]
});