export default [
    {
        path: "/user",
        name: "User",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/user/login",
        children: [
            {
                path: "/user/login",
                name: "UserLogin",
                component: () => import("@/views/user/Login.vue"),
                meta: {
                    tabName: "登录",
                    keepAlive: true,
                    bgColor: "#f9f9f9"
                }
            },
            {
                path: "/user/address/list",
                name: "UserAddressList",
                component: () => import("@/views/user/AddressList.vue"),
                meta: {
                    tabName: "地址管理",
                    keepAlive: false,
                    token: true,
                    bgColor: "#f9f9f9"
                }
            },
            {
                path: "/user/address/edit",
                name: "UserAddressEdit",
                component: () => import("@/views/user/AddressEdit.vue"),
                meta: {
                    tabName: "地址编辑",
                    keepAlive: false,
                    token: true,
                    bgColor: "#f9f9f9"
                }
            },
            {
                path: "/user/customer/list",
                name: "UserCustomerList",
                component: () => import("@/views/user/CustomerList.vue"),
                meta: {
                    tabName: "常用信息管理",
                    keepAlive: false,
                    token: true,
                    bgColor: "#f9f9f9"
                }
            },
            {
                path: "/user/customer/edit",
                name: "UserCustomerEdit",
                component: () => import("@/views/user/CustomerEdit.vue"),
                meta: {
                    tabName: "常用信息编辑",
                    keepAlive: false,
                    token: true,
                    bgColor: "#f9f9f9"
                }
            }
        ]
    }
];
