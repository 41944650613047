export default [
    {
        path: "/product",
        name: "Product",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/product/search",
        children: [
            {
                path: "search",
                name: "ProductSearch",
                component: () => import("@/views/product/Search.vue"),
                meta: {
                    tabName: "商品搜索",
                    keepAlive: true,
                    bgColor: "#f9f9f9"
                }
            },
            {
                path: "detail",
                name: "ProductDetail",
                component: () => import("@/views/product/Detail.vue"),
                meta: {
                    tabName: "商品详情",
                    keepAlive: true,
                    bgColor: "#f9f9f9"
                }
            }
        ]
    }
];
