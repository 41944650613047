import { createRouter, createWebHistory } from "vue-router";
import routers from "./modules/index";
import { start, close } from '@/utils/nprogress';
import { getLoginUrl, getToken } from "@/utils/login";
import { toastFail, toastClose } from "@/utils/toast";

const routes = [
    {
        key: "",
        name: "index",
        path: "/",
        redirect: "/home",
    },
    ...routers,
    {
        path: "/:pathMatch(.*)",
        redirect: "/404"
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});

router.beforeEach(async (to, from, next) => {
    start();
    to.meta.tabName && (document.title = to.meta.tabName);
    // document.body.style.cssText = "background-color:#f6f6f2 !important";
    if (to.meta.token && !getToken()) {
        toastFail("请先登录！")
        next(getLoginUrl());
        return;
    }
    next();
});

router.afterEach(to => {
    close();
    toastClose(true);
});

router.onError(() => {
    console.log("route错误");
});

export default router;
