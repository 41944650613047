export default [
    {
        path: "/order",
        name: "Order",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/order/create",
        children: [
            {
                path: "create",
                name: "OrderCreate",
                component: () => import("@/views/order/create.vue"),
                meta: {
                    tabName: "订单创建页",
                    keepAlive: true,
                    bgColor: "#f9f9f9"
                }
            }
        ]
    }
];
