export default [
    {
        path: "/tab",
        name: "App",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/home",
        children: [
            {
                path: "/home",
                name: "Home",
                component: () => import("@/views/tabs/Home.vue"),
                meta: {
                    tabName: "首页",
                    token: false,
                    keepAlive: true,
                    bgColor: "#ffffff"
                }
            },
            {
                path: "category",
                name: "TabCategory",
                component: () => import("@/views/tabs/Category.vue"),
                meta: {
                    tabName: "分类",
                    token: false,
                    keepAlive: true
                }
            },
            {
                path: "cart",
                name: "TabCart",
                component: () => import("@/views/tabs/Cart.vue"),
                meta: {
                    tabName: "购物车",
                    token: false,
                    keepAlive: true
                }
            },
            {
                path: "mine",
                name: "TabMine",
                component: () => import("@/views/tabs/Mine.vue"),
                meta: {
                    tabName: "我的",
                    token: false,
                    keepAlive: true
                }
            }
        ]
    }
];
